import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
    {
        name: "main", 
        path: "/views", 
        component: () => import("@/views/main.vue"),
        children: [
            {
                name: "home",
                path: "home",
                component: () => import("@/views/home.vue")
            },
            {
                name: "projectList",
                path: "projectList",
                component: () => import("@/views/projectList.vue")
            },
            {
                name: "create",
                path: "create/:code/:type?",
                component: () => import("@/views/create/create.vue")
            },
            {
                name: "stepOne",
                path: "stepOne/:code/:type?",
                component: () => import("@/views/create/stepOne.vue")
            },
            {
                name: "project",
                path: "project/:code",
                component: () => import("@/views/project.vue")
            },
            {
                name: "script",
                path: "script/:id",
                component: () => import("@/views/audit/script.vue")
            },
            {
                name: "projectScript",
                path: "projectScript/:code",
                component: () => import("@/views/audit/script.vue")
            },
            {
                name: "material",
                path: "material/:projectCode/:id?",
                component: () => import("@/views/audit/material.vue")
            },
            {
                name: "expertAudit",
                path: "expertAudit/:code",
                component: () => import("@/views/audit/expertAudit.vue")
            },
            {
                name: "conclusionReport",
                path: "conclusionReport/:code",
                component: () => import("@/views/audit/conclusionReport.vue")
            },
            {
                name: "scriptList",
                path: "scriptList",
                component: () => import("@/views/data/scriptList.vue")
            },
            {
                name: "actorList",
                path: "actorList",
                component: () => import("@/views/data/actorList.vue")
            },
            {
                name: "actor",
                path: "actor/:id",
                component: () => import("@/views/data/actor.vue")
            },
            {
                name: "performingList",
                path: "performingList",
                component: () => import("@/views/data/performingList.vue")
            },
            {
                name: "blackList",
                path: "blackList",
                component: () => import("@/views/data/blackList.vue")
            },
            {
                name: "library",
                path: "library",
                component: () => import("@/views/library/library.vue"),
                children: [
                    {
                        name: "personManagement", 
                        path: "personManagement", 
                        component: () => import("@/views/library/personManagement.vue")
                    },
                    {
                        name: "personEdit",
                        path: "personEdit/:id?",
                        component: () => import("../views/library/personEdit.vue")
                    },
                    {
                        name: 'performingCompanyEdit',
                        path: 'performingCompanyEdit/:id?',
                        component: () => import("@/views/library/performingCompanyEdit.vue")
                    },
                    {
                        name: "performingCompanyManagement",
                        path: "performingCompanyManagement",
                        component: () => import("@/views/library/performingCompanyManagement.vue")
                    },
                    {
                        name: "eventManagement",
                        path: "eventManagement",
                        component: () => import("@/views/library/eventManagement.vue")
                    },
                    {
                        name: "eventEdit",
                        path: "eventEdit/:id?",
                        component: () => import("@/views/library/eventEdit.vue")
                    },
                    {
                        name: "expertManagement",
                        path: "expertManagement",
                        component: () => import("@/views/library/expertManagement.vue")
                    },
                    {
                        name: "expertEdit",
                        path: "expertEdit/:id?",
                        component: () => import("@/views/library/expertEdit.vue")
                    },
                    {
                        name: "venueManagement",
                        path: "venueManagement",
                        component: () => import("@/views/library/venueManagement.vue")
                    },
                    {
                        name: "venueEdit",
                        path: "venueEdit/:id?",
                        component: () => import("@/views/library/venueEdit.vue")
                    },
                ]
            },
            {
                name: "system",
                path: "system",
                component: () => import("@/views/system/system.vue"),
                children: [
                    {
                        name: "userManagement",
                        path: "userManagement",
                        component: () => import("@/views/system/userManagement.vue")
                    },
                    {
                        name: "userEdit",
                        path: "userEdit/:username?",
                        component: () => import("@/views/system/userEdit.vue")
                    },
                    {
                        name: "dataConnect",
                        path: "dataConnect",
                        component: () => import("@/views/system/dataConnect.vue")
                    },
                    {
                        name: "mediaConversion",
                        path: "mediaConversion",
                        component: () => import("@/views/system/mediaConversion.vue")
                    }
                ]
            },
            {
                name: 'aigc',
                path: 'aigc',
                component: () => import("@/views/aigc/aigc.vue")
            },
        ]
    },
    {
        name: "login", 
        path: "/login", 
        component: () => import("@/views/login.vue")
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.afterEach((to) => {
    let path = to.fullPath;
    if (path != "/" && path != '/views' && path != '/login') {
        window.sessionStorage.setItem("performingAudit.currentPath", to.fullPath);
    }
})

export default router;
