const sit = {
    url: 'http://localhost:8000',
    captchaEnabled: true,
    hideYd: false,                  // 在项目概览的他区审核历史中是否显示东方怡动的测试项目
};

const uat = {
    url: "/api",
    // url: 'http://139.196.17.50:18024',
    captchaEnabled: true,
    hideYd: false
};

const prod = {
    url: "/api",  // 表示打包到一起了
    captchaEnabled: true,
    hideYd: true
};

export default {
    aigcEnabled: false,                  // 界面上打开/关闭和aigc相关的内容，包括摘要、aigc浮窗、aigc面板
    business: {
        finder: {
            organ: {
                cacheLength: 20,        // 演出公司和演出场所查询，当没有输入时缓存历史数据的长度
            },
            person: {
                cacheLength: 20,        // 演职员查询，当没有输入时缓存历史数据的长度
            },
            expert: {
                cacheLength: 20,        // 专家查询，当没有输入时缓存历史数据的长度
            }
        }
    },
    env: {
        sit,
        uat,
        prod,
        current: prod,
    }
}