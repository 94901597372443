import { notification } from 'ant-design-vue';

class Exception {
    constructor(key, code, level, title, msg, data, notify) {
        this._key = (key != null? key.toUpperCase() : null);     // "ERR_"开头的字符串
        this._code = code;   // 6为数字的字符串
        this._level = level; // 异常的级别，和notification的级别对应，有：error, warning, warn, info
        this._title = title; // 异常的中文名称，显示在提示上
        this._msg = msg;     // 显示在说明上
        this._data = data;   // 其他信息
        this._notify = (notify == null? true : notify);   // handle的时候是否要弹出提示
    }

    get notify() {
        return this._notify;
    }

    get key() {
        return this._key;
    }

    get code() {
        return this._code;
    }

    get title() {
        return this._title;
    }

    get level() {
        return this._level;
    }

    get msg() {
        return this._msg;
    }

    get data() {
        return this._data;
    }
}

let lastErrLoginTime = null;

const errors = {
    add(key, code, level, title, msg, notify) {
        if (key == null || code == null || level == null || title == null || msg == null) {
            console.error("errors.add: invalid parameters, key: " + key + ", code: " + code + ", level: " + level +
                    ", title: " + title + ", msg: " + msg);
            return;
        }
        if (this[key.toUpperCase()] != null && this.code != null) {
            console.error("errors.add error: conflict error key or code, key: " + key + ", code: " + code);
            return;
        }
        let err = new Exception(key, code, level, title, msg, null, notify);
        this[key.toUpperCase()] = err;
        this[code] = err;
    },
    create(id, msg, title, data, level, key) { // 如果未预先定义，则msg是需要的
        if (arguments.length == 1 && typeof id == 'object' && id.id != null) {
            return this.create(id.id, id.msg, id.title, id.data, id.level, id.key);
        }
        else {
            let err = this[id];
            if (err != null) {
                // 如果是预定义的异常
                return new Exception(err.key,
                    err.code,
                    (level == null? err.level : level), 
                    (title == null? err.title : title), 
                    (msg == null? err.msg : msg),
                    data,
                    err.notify
                );
            }
            else {
                // 非预定义异常
                let _code = null;
                let _key = null;
                if (isNaN(parseInt(id))) { // id不是数字
                    _key = id
                    if (key != null && !isNaN(parseInt(key))) { // key不空且为数字
                        _code = key;
                    }
                } 
                else { // id是数字
                    _code = id;
                    if (key != null && isNaN(parseInt(key))) { // key不空且不是数字
                        _key = key;
                    }
                }
                if (level == null) {
                    level = 'warning';
                }
                if (title == null) {
                    title = '一般异常';
                }
                return new Exception(_key, _code, level, title, msg, data);
            }
        }
    },
    getDuration(level) {
        level = level.toLowerCase();
        if (level == 'error') {
            return 60;
        }
        else if (level == 'warn' || level == 'warning') {
            return 8;
        }
        else {
            return 4.5;
        }
    },
    notify(id, msg, title, level) {
        if (arguments.length == 1 && typeof id == 'object' && (id.id != null || id.key != null || id.code != null
                || id.msg != null)) {
            let _id = id.id;
            if (_id == null) {
                _id = id.key;
            }
            if (_id == null) {
                _id = id.code;
            }
            this.notify(_id, id.msg, id.title, id.level);
        }
        else {
            if (id != null && this[id] != null) {  // 是Exception

                if (id == 'ERR_LOGIN' && lastErrLoginTime != null && 
                        Date.now() - lastErrLoginTime < 1000) {   // 对于上一个登录超时不超过1s的，不再次报警
                    return;
                }
                else {
                    lastErrLoginTime = Date.now();
                }

                let err = this[id];
                if (level == null) {
                    level = err.level;
                }
                if (id.notify != false) {
                    notification[level]({
                        class: "notification",
                        message: (title == null? err.title : title),
                        description: (msg == null? err.msg : msg),
                        duration: this.getDuration(level)
                    });
                }
            }
            else if (msg != null) {
                if (title == null) {
                    title = '一般异常';
                }
                if (level == null) {
                    level = 'warning';
                }
                notification[level]({
                    class: "notification",
                    message: title,
                    description: msg,
                    duration: this.getDuration(level)
                })
            }
            else {
                console.error("errors.notify: invalid parameters, msg is null, id: ");
                console.error(id);
            }
        }
    },
    //handle(err, instance, info) {
    handle(err) {
        // console.debug("errors.handler: key: " + err.key + ", code: " + err.code + 
        //         ", title: " + err.title + ", msg: " + err.msg);
        if (err != null && (err.key != null || err.code != null) && err.msg != null) {
            if (err.notify) {
                errors.notify(err);
            }
            return false;
        }
        else {
            // console.error("errors.handle: invalid err, here's the 'err', 'instance' and 'info':");
            console.error(err);
            // console.log(instance);
            // console.log(info);
        }
    }
};

// 网络异常
errors.add('ERR_NETWORK_UNKNOWN', '010000', 'error', '网络异常', '未知网络异常，请确认服务是否可用，连接服务的网络是否稳定。');
errors.add('ERR_SERVICE_UNAVAILABLE', '011000', 'error', '服务不可用', '请确认服务是否可用，连接服务的网络是否稳定。');

// 400异常
errors.add('ERR_BAD_REQUEST', '400000', 'warning', '请求参数不正确(400: Bad Request)', '请检查所有的请求参数。');
errors.add('ERR_INVALID_PARAMETERS', '401000', 'warning', '提交数据不正确', '输入的数据不符合校验的要求，请仔细检查所有输入的数据。');

errors.add('ERR_UNKNOWN', '999999', 'error', '未知异常', '未知异常，请联系技术支持人员。');

errors.add('ERR_LOGIN', '000001', 'info', '重新登录', '系统超时、会话不可用，需重新登录。', false);

export default errors;