import { createApp } from 'vue'
import App from './App.vue'

// ant-design-vue
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';

// 路由
import router from './router/router.js';

// 全局样式
import './css/theme.css';
import './css/pa-global.css';

import errors from './utils/errors.js';

let app = createApp(App);

app.use(Antd).use(router)

app.config.errorHandler = errors.handle;

app.mount('#app')
