<template>
    <a-config-provider 
            :locale="locale"
            componentSize="large">
        <div>
            <router-view></router-view>
        </div>
    </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';

import store from '@/store/store.js';
import errors from '@/utils/errors.js';
// import userService from '@/services/user.js';

dayjs.locale('zh-cn');

export default {
    name: 'App',
    data() {
        return {
            locale: zhCN
        }
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            let username = window.sessionStorage.getItem("performingAudit.username");
            if (username != null) {
                try {
                    if (store.currentUser == null) {
                        let json = window.sessionStorage.getItem("performingAudit.currentUser.json");
                        if (json != null) {
                            let user = JSON.parse(json);
                            if (user.username != null && user.district != null) {
                                store.currentUser = user;
                            }
                        }
                    }
                    // let user = await userService.getCurrentUser();
                    // if (user != null) {
                    //     store.currentUser = user;
                    // }
                    if (store.currentUser == null) {
                        this.$router.push({name: 'login'});
                    }
                    else {
                        this.$router.push({name: 'main'});
                    }
                }
                catch (err) {
                    errors.notify(err);
                }
            }
            else {
                this.$router.push({name: 'login'});
            }

        }
    }
}
</script>

<style>
#app {
    width: 100%;
    height: 100%;
}
</style>
